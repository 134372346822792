import axios from 'axios';
import _ from 'lodash';
import {mapGetters} from 'vuex';
import notification from '../../../../../notification/notify';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  props: [
    'field',
    'data',
    'dictionaries',
    'backend_errors',
    'inputRequiredEmpty',
  ],
  data() {
    return {
      index: null,
      loading: false,
      galleries: [],
      fileLists: [],
      downloadProgressPercent: 0,
    };
  },
  computed: {
    ...mapGetters('modal', ['getIdTracker']),
    // downloadProgressStatus() {
    //   return this.downloadProgressPercent === 100 ? 'success' : null
    // }
  },
  methods: {
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    setData(data) {
      _.forEach(data, (value) => {
        this.setItemToFileList(value);
        if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(value.filename)) {
          this.setItemToGalleryList(value);
        }
      });
    },
    setItemToGalleryList(item) {
      this.galleries.push({
        title: item.filename,
        src: '',
        thumb: '',
        id: item.id,
      });
    },
    setItemToFileList(item) {
      this.fileLists.push({
        nameWithSize: item.filename + ' (' + this.formatBytes(item.filesize) + ')',
        name: item.filename,
        status: '',
        url: null,
        downloading: false,
        filesize: item.filesize,
        // path: item.path,
        id: item.id,
      });
    },
    checkIfImage(fileName) {
      return (/\.(gif|jpg|jpeg|tiff|png)$/i).test(fileName);
    },
    addNewImagesToGallery() {
      if (this.newFiles && this.newFiles.length > 0) {
        this.newFiles.forEach((item) => {
          if (this.checkIfImage(item.name) && !_.find(this.galleries, ['id', item.uid])) {
            this.galleries.push({
              title: item.name,
              src: URL.createObjectURL(item.raw),
              id: item.uid,
            });
          }
        });
      }
    },
    async onPreview(file) {
      let vm = this;
      let index = 0;
      this.addNewImagesToGallery();
      if ((/\.(gif|jpg|jpeg|tiff|png)$/i).test(file.name)) {
        if (file.raw) {
          index = _.findIndex(vm.galleries, function (item) {
            return item.id === file.uid;
          });
        } else {
          index = _.findIndex(vm.galleries, function (item) {
            return item.id === file.id;
          });
        }
        this.index = 9999;
        await this.loadImages(index);
        this.openGallery(index);
      } else {
        this.loading = true;
        this.downloadFile(file);
      }
    },
    downloadFile(file) {
      if (file.id) {
        file.downloading = true;
        axios.get(`issue/${this.getIdTracker}/attachment/${file.id}`, {
          responseType: 'blob',
          onDownloadProgress: (progressEvent) => {
            this.downloadProgressPercent = Math.round((progressEvent.loaded * 100) / file.filesize);
          },
        }).then((response) => {
          const link = document.createElement('a');
          document.body.appendChild(link);
          link.style = 'display: none';
          const url = window.URL.createObjectURL(new Blob([response.data]));
          link.href = url;
          link.download = file.name;
          // window.URL.revokeObjectURL(url);
          this.loading = false;
          setTimeout(() => {
            link.click();
            file.downloading = false;
            this.downloadProgressPercent = 0;
          }, 500);
        })
          .catch((error) => {
            setTimeout(() => {
              file.downloading = false;
              this.downloadProgressPercent = 0;
            }, 500);
            this.loading = false;
            notification.notify(
              this.$t('notify.error'),
              this.$t(error.response.data.message),
              'error');
          });
      } else {
        const url = window.URL.createObjectURL(new Blob([file.raw]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file.name);
        link.click();
        URL.createObjectURL(file.raw);
        this.loading = false;
      }
    },
    openGallery(index) {
      this.index = index;
      // this.$refs.lightbox.showImage(index)
    },
    loadImage(key, value) {
      return new Promise((resolve) => {
        if (!value.src) {
          axios.get(`issue/${this.getIdTracker}/attachment/${value.id}`, {params: {base64: true}})
            // axios.post('image', {path: value.path})
            .then((response) => {
              const src = 'data: ' + response.data.mime + ';base64,' + response.data.url;
              this.galleries[key].src = src;
              resolve();
            })
            .catch((error) => {
              notification.notify(
                this.$t('notify.error'),
                this.$t(error.response.data.message),
                'error');
              resolve();
            });
        } else {
          resolve();
        }
      });
    },
    async loadImages(firstImageIdx) {
      await this.loadImage(firstImageIdx, this.galleries[firstImageIdx]);
      _.forEach(this.galleries, (value, key) => {
        if (key !== firstImageIdx) {
          this.loadImage(key, value);
        }
      });
    },
  },
};
